@import "../styles/utils.scss";

.btn {
    &.accent-blue {
        padding: 5px 20px;
        background: #007afc;
        color: $white;
        border-radius: 20px;
        font-family: $nexa;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
    }
    &.transparent {
        background: transparent;
        border: 1.5px solid $light-blue;
        border-radius: 20px;
        color: $white;
        padding: 6px 28px;
    }
    &.just-text {
        background: transparent;
        border: none;
        color: $white;
        padding: 6px 28px;
        font-family: $nexa;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
    &.footer-item {
        background: transparent;
        border: none;
        color: $white;
        padding: 6px 28px;
        font-family: $nexa;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
    &.nav-btn {
        padding: 1px 8px;
        color: $white;
        font-family: $nexa;
        font-weight: 900;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
}
