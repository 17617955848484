@import "../styles/utils.scss";

.navbar {
    &-nav {
        flex-grow: 3;
        padding: 28px 25px;
        width: 562px;

        .nav-item {
            font-family: $nexa;
            font-weight: 900;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            padding: 10px;
            a {
                color: $white;
                text-decoration: none;
            }
        }
    }

    &.fixed {
        position: fixed;
        width: 100%;
        background: $dark-blue;
        box-shadow: 0px 0px 5px $blue;
        z-index: 999;
        .navbar-nav {
            padding: 18px 15px;
        }
    }

    &-toggle {
        display: none;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 10;

        &:focus {
            outline: none;
        }

        span {
            width: 2rem;
            height: 0.25rem;
            background: $white;
            border-radius: 10px;
            transition: all 0.3s linear;
            position: relative;
            transform-origin: 1px;

            &.open {
                &:nth-child(1) {
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .navbar-nav {
            flex-direction: column;
            padding: 30px 0;
            justify-content: space-around;

            .nav-item {
                padding: 2px 5px;
                border: 1px solid red;
                border-radius: 15px;
                border: 1.5px solid $light-blue;
            }
        }

        .navbar-toggle {
            display: flex;
        }
    }

    .mobile {
        position: absolute;
        right: 0;
        top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 30px 0;
        width: 100%;
        background: $dark-blue;
        box-shadow: 0px 0px 5px $blue;
        list-style: none;
        z-index: 10;
        .nav-item {
            padding: 2px 2px;
            border-radius: 15px;
            border: 1.5px solid $light-blue;
        }

        &.open {
            display: 1;
        }
    }
}
