@import "../styles/utils.scss";

.icon-content {
    .icon {
        width: 70px;
        height: 70px;
    }

    .description {
        font-family: $nexa;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #a0aaba;
        padding: 25px 50px;
    }
}
