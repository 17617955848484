@import "../styles/utils.scss";

.footer {
    position: relative;
    background: #0e0e0e;
    padding: 82px 0;

    .row-items {
        border-bottom: 1px solid #a0aaba;

        .footer-items {
            padding: 59px 0 28px;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 30px;
            }
            .footer-item {
                list-style: none;
            }

            .footer-item a {
                font-family: $nexa;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                color: #a0aaba;
                text-decoration: none;
                list-style: none;
            }
        }

        .app-icon {
            width: 120px;
            height: 36px;
            fill: #a0aaba;
        }
    }
    .row-rights {
        font-family: $nexa;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #a0aaba;
        margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
    }
}
