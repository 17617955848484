@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

// Main Colors
$white: #ffffff;
$grey: #e4e4e4;

$blue: #132a5b;
$dark-blue: #060f28;
$black: #24252E;

// Accent Colors
$light-blue: #1679DB;
$red: #e0193b;
$green: #0EE583;
$yellow: #F9C337;

//Paths
$assetsPath: "/assets";

//Fonts
$sans: "Open Sans", sans-serif;
$nexa: "Nexa", sans-serif;
