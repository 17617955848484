@import "../styles/utils.scss";

.content-image {
    position: relative;
    z-index: 9;

    .title {
        font-family: $nexa;
        font-style: normal;
        font-weight: 900;
        font-size: 42px;
        line-height: 50px;
        color: $white;
        span {
            color: #007AFC;
        }
    }
    .description {
        font-family: $nexa;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #a0aaba;
        text-align: left;
    }
}
