@import "../styles/utils.scss";

.hero {
    padding: 100px 0;
    position: relative;
    font-family: $nexa;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    line-height: 27px;
    .title,
    .description {
        color: $white;
    }
    .title {
        font-weight: 900;
        font-size: 42px;
        line-height: 64px;
    }
    .description {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
    }


    @media screen and (max-width: 768px) {
        .img {
            padding-top: 100px;
            width: 100%;
        }
    }
}
