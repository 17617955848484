@import "./styles/utils.scss";

.app {
    background: $dark-blue;
    background-image: url("./assets/map-lines.svg");
    background-size: cover;
    background-repeat: no-repeat;
    overflow: scroll;

    .overlay {
        position: absolute;
        background-color: $dark-blue;
        opacity: 0.9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .subtitle {
        font-family: $nexa;
        font-style: normal;
        font-weight: 900;
        font-size: 42px;
        line-height: 64px;
        color: $white;
        z-index: 1;
    }

    .benefits {
        z-index: 1;
        position: relative;
        &-title {
            font-family: $nexa;
            font-style: normal;
            font-weight: 900;
            font-size: 42px;
            line-height: 64px;
            color: $white;
            margin-top: 148px;
        }
        &-description {
            font-family: $nexa;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #a0aaba;
            margin-bottom: 135px;
        }
    }

    .download-cta {
        position: relative;
        padding: 150px 0;
        &-title {
            font-family: $nexa;
            color: $white;
            font-style: normal;
            font-weight: 900;
            font-size: 50px;
            line-height: 76px;
            text-align: center;
            @media screen and (max-width: 768px) {
                font-size: 40px;
                line-height: 50px;
            }
        }
        &-description {
            font-family: $nexa;
            color: $white;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 27px;
            text-align: center;
        }

        .wrapper {
            background: #132a5b;
            border-radius: 20px;
            padding: 68px 250px;
            margin-top: 190px;
            &-title {
                color: $white;
                font-family: $nexa;
                font-style: normal;
                font-weight: 900;
                font-size: 42px;
                line-height: 64px;
            }
            &-description {
                font-family: $nexa;
                color: #a0aaba;
                text-align: center;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
            }

            .text-tag {
                font-family: $nexa;
                color: #a0aaba;
            }

            .store-buttons {
                width: 100%;
                display: flex;
                justify-content: space-around;
            }
        }
        @media screen and (max-width: 768px) {
            .onboarding-img {
                width: 100%;
            }

            .wrapper-download {
                padding: 40px 32px;
                font-family: "Nexa";
                font-style: normal;
                .wrapper-title {
                    font-weight: 900;
                    font-size: 28px;
                    line-height: 42px;
                    text-align: center;
                }
                .store-buttons {
                    flex-direction: column;
                    align-items: center;
                    gap: 25px;
                    .store-icon {
                        width: 100%;
                    }
                }
            }
        }
    }
}
